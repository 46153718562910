<template>
  <b-card class="vs-con-loading__container">
    <!-- add or edit group popup -->
    <b-modal
      id="modal-prevent-closing"
      content-class="shadow"
      :title="isAddPopup ? $t('Add User Group') : $t('Edit User Group')"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      @ok="isAddPopup ? addGroup() : editGroup()"
      centered
    >
      <b-form-group
        :state="nameState"
        :label="$t('Group Name')"
        label-for="name-input"
        invalid-feedback="Name is required"
      >
        <b-form-input
          type="text"
          name="groupName"
          class="shadow"
          :placeholder="$t('Group Name')"
          icon-no-border
          icon="icon icon-briefcase"
          icon-pack="feather"
          :label-placeholder="$t('Group Name')"
          v-model="selected_group_name"
        />
        <div class="card p-2 shadow my-1 ">
          <div class="d-flex align-items-center text-success">
            <briefcase-icon size="2x"></briefcase-icon>
            <p class="title">
              {{ $t("AVV") }}
            </p>
          </div>
          <hr class="mt-1" />
          <b-form-checkbox
            v-for="option in optionsAvv"
            :key="option.value"
            v-model="selected"
            class="custom-control-success custom"
            :value="option.value"
            name="flavour-3a"
          >
            <span class="margin-left-custom">{{ option.text }}</span>
          </b-form-checkbox>
        </div>

        <div class="card p-2 shadow my-1 ">
          <div class="d-flex align-items-center text-warning">
            <home-icon size="2x"></home-icon>
            <p class="title">
              {{ $t("FACILITY") }}
            </p>
          </div>
          <hr class="mt-1" />
          <b-form-checkbox
            v-for="option in optionsFacility"
            :key="option.value"
            v-model="selected"
            class="custom-control-warning custom"
            :value="option.value"
            name="flavour-3a"
          >
            <span class="margin-left-custom">{{ option.text }}</span>
          </b-form-checkbox>
        </div>

        <div class="card p-2 shadow my-1 ">
          <div class="d-flex align-items-center text-info">
            <user-icon size="2x" class="custom-class"></user-icon>
            <p class="title">
              {{ $t("CUSTOMER") }}
            </p>
          </div>
          <hr class="mt-1" />
          <b-form-checkbox
            v-for="option in optionsCustomer"
            :key="option.value"
            v-model="selected"
            class="custom-control-info custom"
            :value="option.value"
            name="flavour-3a"
          >
            <span class="margin-left-custom">{{ option.text }}</span>
          </b-form-checkbox>
        </div>
      </b-form-group>
      <span class="float-left ml-4">
        <!-- <tree-menu-rule
            v-if="isPopupActive"
            v-model="selected"
            :tree="items"
          ></tree-menu-rule> -->
      </span>
      <div class="clear-both"></div>
    </b-modal>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Show</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t("Show") }}</label>
        </b-col>

        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('Search...')"
            />
            <b-button
              @click="openAddPopup"
              v-b-modal.modal-prevent-closing
              variant="primary"
            >
              <span class="text-nowrap">{{
                $t("Add Group Name")
              }}</span></b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refUserListTable"
      class="text-left position-relative"
      striped
      :items="filtered_person_data"
      :current-page="currentPage"
      responsive
      :fields="column_data"
      :per-page="perPage"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(actions)="data">
        <b-dropdown
          id="dropdown-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          text="Primary"
          variant="primary"
        >
          <b-dropdown-item @click="deleteGroup(data.item.group_id)"
            ><feather-icon icon="XIcon" /> Grubu Sil</b-dropdown-item
          >
          <b-dropdown-item
            @click="
              {
                {
                  isAddPopup = true;
                }
              }
            "
            ><feather-icon icon="EditIcon" /> Grubu Düzenle</b-dropdown-item
          >
        </b-dropdown>
      </template>

      <template #cell(işlemler)="data">
        <template v-if="isMobile === true">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="
                openEditPopup(
                  data.item.menu_role_group_id,
                  data.item.group_name,
                  data.item.allowed_menus
                )
              "
              v-b-modal.modal-prevent-closing
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteGroup(data.item.menu_role_group_id)">
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50">{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template v-if="isMobile === false">
          <b-button
            size="sm"
            class="mr-1"
            variant="primary"
            @click="
              openEditPopup(
                data.item.menu_role_group_id,
                data.item.group_name,
                data.item.allowed_menus
              )
            "
            v-b-modal.modal-prevent-closing
            >{{ $t("Edit") }}</b-button
          >
          <b-button
            size="sm"
            class="mr-1"
            variant="danger"
            @click="deleteGroup(data.item.menu_role_group_id)"
            >{{ $t("Delete") }}</b-button
          >
        </template>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm=""
          class="
              d-flex
              align-items-right
              justify-content-right justify-content-sm-end
              radius 0
            "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="total_item"
            :per-page="perPage"
            first-number
            last-number
            align="right"
            class="mb-0 mt-1 mt-sm-0 r-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import vSelect from "vue-select";
import TreeMenuRule from "@/components/TreeMenuRole/TreeMenuRole.vue";
import { BriefcaseIcon, HomeIcon, UserIcon } from "vue-feather-icons";
import {
  BRow,
  BCard,
  BCol,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  BButton,
  BTable,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  name: "Permissions",
  components: {
    BRow,
    BCard,
    BCol,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BTable,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    TreeMenuRule,
    //   vsPopup
    vSelect,
    //icons
    BriefcaseIcon,
    HomeIcon,
    UserIcon,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      //search kısmı
      currentPage: null,
      perPage: 25,
      perPageOptions: [10, 25, 50, 100],
      total_item: null,
      searchQuery: "",
      isSortDirDesc: true,
      sortBy: "id",

      name: "",
      selected_group_id: null,
      selected_group_name: "",
      nameState: null,
      isMounted: false,
      isPopupActive: false,
      isAddPopup: true,
      groupName: "",
      groups: [],
      selected: [],
      selectedIndex: "",
      optionsAvv: [
        { text: this.$t("Facilities"), value: "Stüdyolar" },
        // { text: this.$t('Parameters'), value: 'Parametreler'},
        { text: this.$t("User Groups"), value: "Kullanıcı Grupları" },
      ],
      optionsFacility: [
        { text: this.$t("Customers"), value: "Müşteriler" },
        { text: this.$t("Activities"), value: "Aktiviteler" },
        { text: this.$t("Rooms"), value: "Salonlar" },
        { text: this.$t("Trainers"), value: "Eğitmenler" },
      ],
      optionsCustomer: [
        { text: this.$t("Appointments"), value: "Randevularım" },
        { text: this.$t("Accounts"), value: "Hesaplarım" },
      ],
      column_data: [
        { key: "group_name", label: this.$t("Group Name") },
        { key: "işlemler", label: this.$t("TRANSACTIONS") },
      ],
      personData: [],
    };
  },
  methods: {
    group_name(id, name) {
      this.selected_group_id = id;
      this.selected_group_name = name;
      this.selected;
      this.isAddPopup = false;
    },
    addGroup() {
      const me = this;
      if (this.selected_group_name === "") return;
      if (!this.groups.some((el) => el.name === this.selected_group_name)) {
        this.$database.SettingService.menu_role_group_create(
          this.selected_group_name,
          JSON.stringify(this.selected)
        ).then((res) => {
          if (res.is_success === true) {
            me.isPopupActive = false;
            this.$functions.Messages.success(
              "User Group Successfully Identified"
            );
            this.getMenuRoleGroups();
          } else {
            this.$functions.Messages.error("Action failed!");
          }
        });
        // this.groups.push({name: this.groupName.slice(), menus: this.selected.slice()})
      } else {
        // SweetAlert.OperationErrorMessage('Aynı isimde grup mevcut!')
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });
    },

    async deleteGroup(index) {
      //silinmemesi gereken kullanıcı grupları
      const defaultID = [
        "5be25ecb-1a4a-43fb-9bd2-bca6b23d4d10",
        "fd69984d-0471-47c0-8636-5bdcd2c3190b",
        "ceb6fb46-946e-4e07-84a2-3431b608d1c7",
        "7de48788-ba4d-44c7-b863-5158270c6488",
      ];

      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Are You Sure You Want to Delete User Group Name"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          if (defaultID.some((i) => i === index)) {
            this.$functions.Messages.error("You Cannot Delete The User Group");
          } else {
            this.$database.SettingService.menu_role_group_delete(index).then(
              (res) => {
                if (res.is_success === true) {
                  this.$functions.Messages.success(
                    "User Group Successfully Deleted"
                  );
                  this.getMenuRoleGroups();
                } else {
                  this.$functions.Messages.error("Action failed!");
                }
              }
            );
          }
        }
      });
    },
    openEditPopup(index, groupName, allowedMenus) {
      this.selectedIndex = index;
      this.selected_group_name = groupName;
      this.selected = [];
      this.selected = [...JSON.parse(allowedMenus)];
      //this.selected = [...JSON.parse(allowedMenus)];
      this.isAddPopup = false;
      this.isPopupActive = true;
    },
    openAddPopup() {
      this.selected_group_name = "";
      this.selected = [];
      this.isAddPopup = true;
      this.isPopupActive = true;
    },
    editGroup() {
      this.$database.SettingService.menu_role_group_update(
        this.selectedIndex,
        this.selected_group_name,
        JSON.stringify(this.selected)
      ).then((res) => {
        if (res.is_success === true) {
          this.isPopupActive = false;
          this.$functions.Messages.success("User Group Successfully Edited");
          this.getMenuRoleGroups();
        } else {
          this.$functions.Messages.error("Action failed!");
        }
      });
    },
    getMenuRoleGroups() {
      const me = this;
      this.$database.SettingService.menu_role_group_get().then((res) => {
        me.groups = res.result;
        // console.log(res.result);
      });
    },
  },
  watch: {
    groups(val) {
      this.total_item = val.length;
    },
  },
  computed: {
    filtered_person_data() {
      let data = [];
      this.groups.map((x) => {
        if (
          x.group_name.toUpperCase().includes(this.searchQuery.toUpperCase())
        ) {
          data.push(x);
          this.total_item = data.length;
          this.currentPage = 1;
        }
      });
      // let _data = data.slice(((this.currentPage-1)*this.perPage),(((this.currentPage-1)*this.perPage)+this.perPage))
      //  return _data.length === 0 ? data : _data
      return data;
    },
    // currentPage() {
    //   if (this.isMounted) {
    //     return this.$refs.table ? this.$refs.table.currentx : 0;
    //   }
    //   return 0;
    // },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.groups.length;
    },
    items() {
      return menuItems;
      // return [{name: 'Anamenü'},{name: 'Çözüm Ortakları'},{name: 'Müşteriler'},{name:'Sicil İşlemleri', submenu: [{name: 'Tahakkuk İşlemleri'}]}]
    },
  },
  created() {
    this.getMenuRoleGroups();
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" src="@/styles/scss/permission.scss" scoped></style>
